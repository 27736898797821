
body{
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 12px;
}
h1, h2, h3, h4, h5, h6{
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	-webkit-font-smoothing: antialiased;
}
q{
	-webkit-font-smoothing: antialiased;
}