

.quote{
	$basis: 100px;
	$height-compressor: 1;
	$type-compressor: .9;
	$arrow-compressor: .50;
	$padding-compressor: .50;
	min-height: ($basis * $height-compressor);
	position: relative;
	z-index: 2;
	background-color: $white;
	.quote-copy{
		display: flex;
		align-items: center;
		justify-content: center;
		height: ($basis * $height-compressor);
		q{
			color: $grey-quote-text;
			font-weight: 300;
			font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
			font-size: (($basis * $type-compressor) / 5);
			font-style: normal;
			text-align: center;
			display: block;
			padding-left: (($basis * $padding-compressor)/2);
			padding-right: (($basis * $padding-compressor)/2);
			line-height: (80/73);
			text-transform: uppercase;
			&:before, &:after{
				content: '';
			}
		}
		p{
			//reset this now because it is a wysiwig
			margin-top: 0;
			margin-bottom: 0;
			padding-top: 0;
			padding-bottom: 0;
		}

	}
	.triangle-holder{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		.triangle-hold{
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

		}
		.triangle{
			

			background-color: transparent;

			width: 0; 
			height: 0; 
			border-top: ( ($basis * $arrow-compressor) * (3/10) ) solid transparent;
			border-bottom: ( ($basis * $arrow-compressor) * (3/10) ) solid transparent;
			
			border-left: ( ($basis * $arrow-compressor) * (6/10) ) solid $green-arrow-side;
		}
	}
	
}
@include breakpoint($tabletup){
	$basis: 140px;
	$height-compressor: 1;
	$type-compressor: 1;
	$arrow-compressor: 1;
	$padding-compressor: 1;
	.quote{
		min-height: ($basis * $height-compressor);

		.quote-copy{

			height: ($basis * $height-compressor);
			q{
				font-weight: 200;
				font-size: (($basis * $type-compressor) / 5);
				letter-spacing: 6px;
				padding-left: (($basis * $padding-compressor)/2);
				padding-right: (($basis * $padding-compressor)/2);
				
			}

		}
		.triangle-holder{
			
			
			.triangle{
				

				background-color: transparent;
				border-top: ( ($basis * $arrow-compressor) * (3/10) ) solid transparent;
				border-bottom: ( ($basis * $arrow-compressor) * (3/10) ) solid transparent;
				border-left: ( ($basis * $arrow-compressor) * (6/10) ) solid $green-arrow-side;
			}
		}
		
	}
}
@include breakpoint($desktop){
	$basis: 180px;
	$height-compressor: 1;
	$type-compressor: 1;
	$first-compressor: 1.3;
	$arrow-compressor: .95;
	$padding-compressor: .97;
	.quote{
		min-height: ($basis * $height-compressor);

		.quote-copy{

			height: ($basis * $height-compressor);
			q{
				
				font-size: (($basis * $type-compressor) / 5);
				letter-spacing: 6px;
				padding-left: (($basis * $padding-compressor)/2);
				padding-right: (($basis * $padding-compressor)/2);
				
			}
			

		}
		&.first .quote-copy q{
			padding-left: (($basis * $first-compressor)/2);
			padding-right: (($basis * $first-compressor)/2);
		}
		.triangle-holder{
			
			
			.triangle{
				

				background-color: transparent;
				border-top: ( ($basis * $arrow-compressor) * (3/10) ) solid transparent;
				border-bottom: ( ($basis * $arrow-compressor) * (3/10) ) solid transparent;
				border-left: ( ($basis * $arrow-compressor) * (6/10) ) solid $green-arrow-side;
			}
		}
		
	}
}