/* glue: 0.11.1 hash: 77d8dd3803 */
        .sprite-sprite-icons-mail,
.sprite-sprite-icons-linked-in,
.sprite-sprite-icons-instagram,
.sprite-sprite-icons-facebook {
            background-image: url('../icons/dist/sprite-icons.png');
            background-repeat: no-repeat;
        }
        
        .sprite-sprite-icons-mail {
            background-position: 0 0;
            width: 60px;
            height: 60px;
        }
        
        .sprite-sprite-icons-linked-in {
            background-position: -60px 0;
            width: 60px;
            height: 60px;
        }
        
        .sprite-sprite-icons-instagram {
            background-position: 0 -60px;
            width: 60px;
            height: 60px;
        }
        
        .sprite-sprite-icons-facebook {
            background-position: -60px -60px;
            width: 60px;
            height: 60px;
        }
        
        @media screen and (-webkit-min-device-pixel-ratio: 1.0), screen and (min--moz-device-pixel-ratio: 1.0), screen and (-o-min-device-pixel-ratio: 100/100), screen and (min-device-pixel-ratio: 1.0), screen and (min-resolution: 1.0dppx) {
            .sprite-sprite-icons-mail,
    .sprite-sprite-icons-linked-in,
    .sprite-sprite-icons-instagram,
    .sprite-sprite-icons-facebook {
                background-image: url('../icons/dist/sprite-icons.png');
                -webkit-background-size: 120px 120px;
                -moz-background-size: 120px 120px;
                background-size: 120px 120px;
            }
        }
        