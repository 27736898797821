
@supports (background-image: filter(url('i.jpg'), blur(1px))) {
  .section.contact.loaded::before {
    transform: translateZ(0);
  }
  .section.contact.loaded::after {
    animation: sharpen .5s both;
  }
  @keyframes sharpen {
    from {
      background-image: filter('../images/contact.jpg', blur(20px));
    }
    to {
      background-image: filter('../images/contact.jpg', blur(0px));
    }
  }
}
.section{
	&.contact{
		background-color: #111A21;
		min-height:100vh;
		
		max-height: 750px;
		height: 100%;
		h1{
			text-align: center;
			color: $white;
			position: relative;
			z-index: 1;
		}
		.col-xs-12{
			
			min-height: 400px;
			max-height: 750px;
			@include vertical-align;
			position: relative;
			z-index: 1;
		}

		overflow: hidden;
		position: relative;
		&::before{
			 	content: ' ';
				position: fixed; // instead of background-attachment
				min-height:100vh;
			
				max-height: 750px;
				width: 100%;
				height: 100%;
				top: $header-height;
				left: 0;
				right: 0;
				background-color: white;
				background: url(../images/contact-small.jpg) no-repeat top center;
				background-size: cover;
				will-change: transform; // creates a new paint layer
				z-index: -2;
				opacity: 1;
			-webkit-transition: opacity .5s ease-in-out;
    		-moz-transition: opacity .5s ease-in-out;
    		transition: opacity .5s ease-in-out;
		}
		&::after{
			 	content: ' ';
				position: fixed; // instead of background-attachment
				min-height:100vh;
			
				max-height: 750px;
				width: 100%;
				height: 100%;
				top: $header-height;
				left: 0;
				right: 0;
				background-color: white;
				background: url(../images/contact.jpg) no-repeat top center;
				background-size: cover;
				will-change: transform; // creates a new paint layer
				z-index: -2;
				opacity: 0;
				-webkit-transition: opacity .5s ease-in-out;
				-moz-transition: opacity .5s ease-in-out;
	    		transition: opacity .5s ease-in-out;

		}
		&.inview{
			&::before, &::after{
				z-index: 0;
			}

		}
		&.loaded{
			&::after{
				opacity: 1;

			}
			&::before{
				opacity: 0;
			}
		}
		
	}
	.flex-container{
		padding: 0;
		margin: 0;
		list-style: none;

		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		h1{
			width: 100%;
			margin-bottom: 35px;
		}

		.flex-item {

		  padding: 5px;
		  width: 60px;
		  height: $fa-base;
		  margin: 10px;
		  
		  line-height: 1;
		  color: white;
		  font-weight: bold;
		  font-size: 3em;
		  text-align: center;
		  position: relative;
		  a{
		  	display: block;
		  }
	
		  
		}
	}
}

.bottom-arrow-holder{
	position: absolute;
	z-index: 1;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 10vh;
	
	.bottom-arrow-hold{
		position: relative;
	}
	.bottom-arrow{
		position: absolute;
		bottom: 0;


		background-color: transparent;

		width: 0; 
		height: 0; 
		border-left: 50vw solid transparent;
		border-right: 50vw solid transparent;
		
		border-bottom: 6vw solid $green-arrow-side;
	}
}


.section .contact-header h1{
	color: $white;
}