@mixin vertical-align{
	display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    height: 100%;
	
}
@mixin vert-align{
		display: flex;
    align-items: center;
    justify-content: center;
}