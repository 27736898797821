//breakpoints
$tabletup: 768px;
$desktop: 960px;
$desktop-lg: 1200px;

//////////////////////////////
// Breakpoint Mixin
//////////////////////////////

@mixin breakpoint($query, $no-query: false) {
 @media (min-width: $query){
    @content;
 }
}

