
@supports (background-image: filter(url('i.jpg'), blur(1px))) {
  .section.hero.loaded::before {
    transform: translateZ(0);
  }
  .section.hero.loaded::after {
    animation: sharpen .5s both;
  }
  @keyframes sharpen {
    from {
      background-image: filter('../images/grand-central.jpg', blur(20px));
    }
    to {
      background-image: filter('../images/grand-central.jpg', blur(0px));
    }
  }
}
.section{
	width: 100%;
	height: 100%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
	position: relative;
	overflow: hidden;
	h1{
		color: $blue-section-headers;
		position: relative;
		text-transform: uppercase;
		display: inline-block;
		
		font-size: 30px;
		&:before{
			content: '';
			position: absolute;
			background-color: $green-contact-underline;
			margin: 0 auto;
			width: 60px;
			left:0;
			right:0;
			height: 6px;
			bottom: -24px;
		}
	}

	&.approach, &.hero{
		.col-xs-12{
			@include vertical-align;
	
		}
	}
	&.hero{
		z-index: 0;
		padding-top: $header-height;
		padding-bottom: 30px;
		box-shadow: inset 0 (1*$header-height) 0 white;
		background-color: $green-contact-underline;
		overflow: hidden;
		position: relative;
		min-height:100vh;
		max-height: 750px;
		height: 100%;
		.col-xs-12{
			//height:100vh;
			min-height: 100vh;
			max-height: 750px;
			height: 100%;
		}

		&::before{
			content: ' ';
			position: fixed; // instead of background-attachment
			width: 100%;
			min-height: 100vh;
    		max-height: 750px;
			height: 100%;
			top: $header-height;
			left: 0;
			background-color: white;
			background: url('../images/grand-central-small.jpg') no-repeat top center;

			background-size: cover;
			will-change: transform; // creates a new paint layer
			z-index: -2;
			opacity: 1;
			-webkit-transition: opacity .5s ease-in-out;
    		-moz-transition: opacity .5s ease-in-out;
    		transition: opacity .5s ease-in-out;

		}
		&::after{
		 	content: ' ';
		 	opacity: 0;
			position: fixed; // instead of background-attachment
			width: 100%;
			min-height: 100vh;
    		max-height: 750px;
			height: 100%;
			top: $header-height;
			left: 0;
			background-color: white;
			background: url('../images/grand-central.jpg') no-repeat top center;
			background-size: cover;
			will-change: transform; // creates a new paint layer
			z-index: -2;
			-webkit-transition: opacity .5s ease-in-out;
			-moz-transition: opacity .5s ease-in-out;
    		transition: opacity .5s ease-in-out;
		}
		&.loaded{
			&::after{
				opacity: 1;

			}
			&::before{
				opacity: 0;
			}
		}
		&.inview{
			&::before, &::after{
				z-index: -1;
			}
		}
		h3{
			line-height: (114/73);
			font-size: 30px;
			text-align: center;
			text-shadow: 0 0 60px rgba(0,0,0, .5);
			color: white;
			position: relative;
			&:before{
				content: '';
				width: 74px;
				height: 6px;
				background-color: $blue-section-overhead;
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				margin: 0 auto;
				top: -15px;
			}
		}
		.scroll-to-arrow{
			position: absolute;
			bottom: 20px;
			left: 0;
			right: 0;
			width: 50px;
			display: block;
			text-align: center;
			margin: 0 auto;
			.fa{
				font-size: 60px;
				color: $white;
			}
		}

	}
	&.approach{
		position: relative;
			z-index: 1;
		background-image: url('../images/ocean.jpg');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;

		p{
			
			text-align: center;

			
		}
		h1{
			text-align: center;
			width: 100%;
		}
		.col-xs-12{
			padding-top: 10vh;
			align-items: flex-start;
		}

	}
	&.biography{
		position: relative;
			z-index: 1;
		background-image: url('../images/arrow.jpg');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		padding: 0;
		//height: auto;
		h1{
			width: 100%;
			text-align: center;
			
		}
		p{
			text-align: center;
		}
		.col-sm-4{
			justify-content: center;
			img{
				margin: 0 auto;
			}
		}
		.col-sm-8, .last{
			padding-left: 36px;
			padding-right: 36px;
		}

	}
	&.biography, &.approach{
		h1{
			
		}
		p{

			margin-top: 23px;
			line-height: 1.8;
			color: $grey;
			font-size: 18px;
    		font-weight: 400;
		}
	}
	&.biography, &.approach, &.contact{
		h1{
			font-family: 'Ubuntu Condensed', sans-serif;
		}
	}

	
}
@include breakpoint($tabletup){
	.section{
		h1{
			letter-spacing: 10px;
			font-size: 40px;
		}
		&.hero{
			h3{
				font-size: 40px;
			}
		}
		&.approach{
			p{
				max-width: 80%;
				margin-left: auto;
				margin-right: auto;
				text-align: justify;
			}
		}
		&.biography{
			h1{
				text-align: left;
				&:before{
					margin-left: 0;
				}
			}
			p{
				text-align: left;
			}
			.col-sm-8, .col-sm-4{
				@include vertical-align;
				min-height: 400px;

				&.last{
					order: 9;
				}
			}
			.col-sm-8, .last{
				padding-left: 12px;
				padding-right: 24px;

			}
			.col-sm-4{
				justify-content: flex-start;
				img{
					margin-left: initial;
					margin-right: initial;
					
				}
			}
		}
	}

}