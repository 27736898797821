* {
	box-sizing:border-box;
}
$translucent: rgba(255,255,255,.9);
$logo-compressor: .95;
.header {

	display:flex;
	position:relative;
	flex-grow:1;
	flex-shrink: 0;
	flex-basis: 90%;
	flex-wrap: wrap; // must be there to allow the navigation to go next line
	font-family: 'Ubuntu Condensed', sans-serif;
	justify-content: center;


}
.fixed-holder{
	background-color: $translucent;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index:3;

	
	
}

.logo {

	position:relative;
	z-index:902;
	display:flex;
	flex-grow:2 ;
	flex-shrink: 0; 
	flex-basis: 20%;
	max-width: (260px * $logo-compressor);
	justify-content:flex-start;
	align-items:center;
	padding: 12px;
	text-decoration:none;
	color: #222;
	img{
		display: block;
		max-width: 100%;
		width: 100%;
	}

}

.navigation-mobile {

	background-color:#fff;
	position:relative;
	z-index:902;
	//make this display flex when ready for navigation to be re-added
	display:none;
	flex-grow: 0;
	flex-shrink: 1;
	flex-basis: auto;
	justify-content:center;

}

.navigation-mobile a {
	//make this flex when you're ready for mobile navigation'
	display: none;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: auto; //at 100% this caused IE11 to suck
	justify-content:center;
	align-items:flex-start; //this also affects the pseudo element
	position:relative;
	padding:12px;
	font-size:12px;
	z-index:991;
	width:100%;
	min-width: 50px;
	@include vert-align;
	color:$blue-section-overhead;
	text-decoration:none;
	cursor:pointer;
	transition:background-color 0.4s ease-in-out;
	.fa{
		
	}
	i:before{
		font-size: 30px;

	}

	&:hover,
	&:active,
	&:focus,
	&.active {

		color: lighten($blue-section-overhead, 10%);

	}

}

.navigation-container {

	display:flex;
	flex-grow: 1;
	flex-shrink: 0;
	flex-basis: 100%;


}

.navigation {
	//this will be undone when the page gets larger // display: block
	display: none;
	padding-left: 0;
	margin-left: 0;

	opacity:0;
	position:absolute;
	left: 0;
	right: 0;
	z-index:900;
	width:100%;
	transition:transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
	transform:translateY(-150%);


}

.navigation.navigation-open {

	transform:translateY(0);
	opacity: 1;
}

.navigation-item {

	display:block;
	width:100%;
	border-bottom:1px solid #eee;
	@include vert-align;

	
}
.navigation-item a {
	font-family: 'Ubuntu Condensed', sans-serif;
	@include vert-align;
	//make this display flex when ready fot it
	display: none;
	text-transform: uppercase;
	display:inline-block;
	letter-spacing: 3px;
	font-weight: 500;
	font-size: 16px;
	width:100%;
	padding:12px 40px;
	text-align: left ;
	position: relative;
	background-color: $translucent;
	color:#222;
	text-decoration:none;
	transition: background-color 0.4s ease-in-out;
	color: $blue-nav-text;

	a{
		&.active, &:hover{
			
			color: darken($blue-nav-text, 10%);
		}
	}
	
}

@include breakpoint($tabletup){
	.header {
		flex-wrap: no-wrap;
		justify-content: space-between;
	}
	.logo{
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: 5em;
		max-width: (260px * $logo-compressor);
		order: 99;
		justify-content:center;
		border-bottom:none;
	}
	.navigation-mobile{
		display:none;
		visibility:hidden;
	}
	.navigation-container{
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: 38%;
		flex-direction:row;
		justify-content:flex-end;
	}
	.navigation{
		transform:translateY(0);
		position:relative;
		display:flex;
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: auto;
		flex-direction:row;
		opacity: 1;
	
	}
	.navigation-item{
		border-bottom:none;
		display:flex;
		flex-wrap:nowrap;
		flex-grow: 1; 
		flex-shrink: 0;
		flex-basis: auto;
		width: auto;
		font-weight: 500;
		letter-spacing: 1px;
		font-size: 16px;
		a{
			padding-left: 16px;
			padding-right: 16px;
			text-align: center;
			width: auto;
			background-color: transparent;
			&:hover, &.active {
				color: $blue-nav-text;

				&:before{
					position: absolute;
					content: '';
					width: 50%;
					height: 3px;
					bottom:0px;
					left:0;
					right: 0;
					margin: 0 auto;
					background-color: $green-active-nav;
				}

			}
		}
	}

}