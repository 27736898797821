$grey: #7c7c7c;
$grey-quote-text: #a4a4a4;
$green-arrow-side: #d5e26a;
$green-contact-underline: #d6e36a;
$green-active-nav: #d2e05d; // and section underlines

$blue-section-overhead: #0e3178;
$blue-nav-text: #0c3a83;
$blue-section-headers: #174389;

$white: #fff;
